<!-- *===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===
  - Project: HR APPLICATION
  - Author: Salwa Abuwarda*
  - Author URL: http://linkedin.com/in/salwa-abuwarda-20b06a142
  ===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===*===* -->

<template>
  <div id="data-list">
   <vs-popup
      classContent="popup-example"
      title="Confirm Update"
      :active.sync="popupActive"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <ValidationProvider 
            name="category_password"
            rules="required|min:8"
            v-slot="{ errors }"
          >
            <vs-input
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              type="password"
              name="category_password"
              icon-no-border
              icon="icon icon-lock"
              icon-pack="feather"
              label-placeholder="Password"
              v-model="category_password"
              class="w-full mt-6"
            />
          </ValidationProvider>

          <vs-button
            :disabled="invalid"
            type="filled"
            @click="updateCategory"
            class="mb-2 mt-5"
            >Confirm</vs-button
          >
        </form>
      </validation-observer>
    </vs-popup>
    <vx-card
      ref="filterCard"
      title="Updating category"
      class="user-list-filters mb-8"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form>
        <div class="vx-row">
          <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
            <label class="text-sm opacity-75"
              ><span class="text-primary">*</span>CATEGORY NAME</label
            >
             <validation-provider
                    v-slot="{ errors }"
                    name="category_name"
                    rules="required|min:2"
                  >
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-terminal"
              icon-no-border
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              v-model="category_name"
            />
             </validation-provider>
          </div>

        </div>

        <vs-button
          :disabled="invalid"
          type="filled"
          @click="openConfirm()"
          class="mb-2"
          >UPDATE</vs-button
        >
      </form>
    </validation-observer>
    </vx-card>
  </div>
</template>

<script>
import axios from "@/axios.js";
import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";
setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty"
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters"
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      category_name: null,
       popupActive: false,
    category_password: null,
    };
  },

  methods: {
    openConfirm(){
 this.popupActive = true;
      },
    updateCategory() {
      this.$vs.loading();
this.popupActive = false;
      axios
        .post(`${process.env.VUE_APP_BASE_URI}/api/admin/categories/${this.$route.query.SID}` , {
          name : this.category_name , password : this.category_password ,_method: "put"
        })
        .then(response => {
           this.$vs.loading.close();
          if(response.status  === 200 ) {
               this.$vs.notify({
                  title: "Success",
                  text: "Category is updated",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "success",

              });
              
              this.$router.push("/GetAll/Category");
          }
          else{
                  this.$vs.notify({
                  title: "Error",
                  text: response.message,
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger",
              });

          }
        })
         .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        });
    },
    resetColFilters(card) {
      card.removeRefreshAnimation(100);
      this.category_name = null;
    },
  },
  mounted() {

    axios
      .get(
        `${process.env.VUE_APP_BASE_URI}/api/admin/categories/${this.$route.query.SID}`
      )
      .then(response => {
        this.category_name = response.data.response.data.name;
      });
  },
};
</script>
