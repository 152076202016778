var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"data-list"}},[_c('vs-popup',{attrs:{"classContent":"popup-example","title":"Confirm Update","active":_vm.popupActive},on:{"update:active":function($event){_vm.popupActive=$event}}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('ValidationProvider',{attrs:{"name":"category_password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-6",attrs:{"danger":!!errors[0],"danger-text":errors[0],"type":"password","name":"category_password","icon-no-border":"","icon":"icon icon-lock","icon-pack":"feather","label-placeholder":"Password"},model:{value:(_vm.category_password),callback:function ($$v) {_vm.category_password=$$v},expression:"category_password"}})]}}],null,true)}),_c('vs-button',{staticClass:"mb-2 mt-5",attrs:{"disabled":invalid,"type":"filled"},on:{"click":_vm.updateCategory}},[_vm._v("Confirm")])],1)]}}])})],1),_c('vx-card',{ref:"filterCard",staticClass:"user-list-filters mb-8",attrs:{"title":"Updating category","collapse-action":"","refresh-content-action":""},on:{"refresh":_vm.resetColFilters}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/2 sm:w-1/2 w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("CATEGORY NAME")]),_c('validation-provider',{attrs:{"name":"category_name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"icon-pack":"feather","icon":"icon-terminal","icon-no-border":"","danger":!!errors[0],"danger-text":errors[0]},model:{value:(_vm.category_name),callback:function ($$v) {_vm.category_name=$$v},expression:"category_name"}})]}}],null,true)})],1)]),_c('vs-button',{staticClass:"mb-2",attrs:{"disabled":invalid,"type":"filled"},on:{"click":function($event){return _vm.openConfirm()}}},[_vm._v("UPDATE")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }